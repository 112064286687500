.code-input {
    width: 50px;
    height: 50px;
    background-color: #1f1e1d;
    text-align: center;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: large;
}

.transcript__box {
    height: 90vh !important;
    height: 90svh !important;
}

.code-input:focus {
    outline: 1px solid #ED9624;
}

.code-container {
    display: flex;
    gap: 10px;
}

#scroll-to-bottom__btn {
    opacity: 0;
    transition: opacity 1s, transform 1s;
    transform: translateY(100%);
}

#scroll-to-bottom__btn.show {
    opacity: 0.5;
    transform: translateY(0);
}